
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

interface Subject {
  _id: string;
  name: string;
}

interface Document {
  icon: {
    file: string;
    color: string;
  };
  _id: string;
  title: string;
  createdAt: string;
  description: string;
  subject: Subject;
  level: number[];

  files: string[];
  status: string;
  reviewDate: string;
  type: string;
  coverPicture: string;
}

export default defineComponent({
  name: "Library",

  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();

    const user = store.getters.currentUser;
    const level = ref<string>(user.level);
    const documents = ref<Document[]>([]);
    const search = ref<string>("");
    const subjects = ref<Subject[]>([]);
    const selectedSubject = ref<string>("");
    const loading = ref(true);
    const validateStat = ref<number>(0);
    const isModalVisible = ref<boolean>(false);
    const levelMapping = {
      "-2": t("document.levels.niveauPre"),
      "-1": t("document.levels.niveau0"),
      "1": t("document.levels.niveau1"),
      "2": t("document.levels.niveau2"),
      "3": t("document.levels.niveau3"),
      "4": t("document.levels.niveau4"),
      "5": t("document.levels.niveau5"),
      "6": t("document.levels.niveau6"),
      "7": t("document.levels.niveau7"),
      "8": t("document.levels.niveau8"),
      "9": t("document.levels.niveau9"),
      "10": t("document.levels.niveau10"),
      "11": t("document.levels.niveau11"),
      "12": t("document.levels.niveau12"),
      "13": t("document.levels.niveau13"),
    };

    const fetchDocuments = () => {
      ApiService.post("/library/item/filter", { query: { status: "active" } })
        .then(({ data }) => {
          documents.value = data.map((document: any) => ({
            _id: document._id,
            title: document.title,
            createdAt: document.createdAt,
            description: document.description,
            subject: document.subject,

            level: document.level,
            files: document.files || [],
            coverPicture: document.coverPicture,
          }));

          subjects.value = data
            .map((doc: any) => doc.subject)
            .flat()
            .filter(
              (subject, index, self) =>
                index === self.findIndex((s) => s._id === subject._id)
            )
            .map((subject: any) => ({
              _id: subject._id,
              name: subject.name,
            }));

          documents.value = documents.value.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        })
        .catch((e) => console.log(e))
       .finally(() => {
          loading.value = false;
        });
    };

    const filteredDocuments = computed(() => {
      let filtered = documents.value;

      if (selectedSubject.value) {
        filtered = filtered.filter((doc) => {
          return Array.isArray(doc.subject)
            ? doc.subject.some((sub) => sub._id === selectedSubject.value)
            : doc.subject._id === selectedSubject.value;
        });
      }

      if (search.value) {
        filtered = filtered.filter((doc) =>
          doc.title.toLowerCase().includes(search.value.toLowerCase())
        );
      }

      return filtered;
    });

    const currentPage = ref(1);

    const paginatedData = computed(() => {
      const startIndex = (currentPage.value - 1) * 10;
      const endIndex = startIndex + 10;
      return filteredDocuments.value.slice(startIndex, endIndex);
    });

    const handleCurrentChange = (val: number) => {
      currentPage.value = val;
    };

    onMounted(() => {
      setCurrentPageTitle(t("document.digital_library"));
      fetchDocuments();
    });

    watch(locale, () => {
      setCurrentPageTitle(t("document.digital_library"));
    });

    const getLevelNames = (level: number) => {
      return levelMapping[level];
    };

    const remainingSubjects = (subjects: any[]) => {
      return subjects
        .slice(2)
        .map((subject) => subject.name)
        .join(", ");
    };

    const remainingLevels = (levels: number[]) => {
      return levels
        .slice(1)
        .map((level) => levelMapping[level])
        .join(", ");
    };

    const handleRowClick = (row) => {
      router.push(`/document/${row._id}`);
    };

    const showModal = () => {
      isModalVisible.value = true;
    };

    const hideModal = () => {
      isModalVisible.value = false;
    };

    return {
      t,
      documents,
      search,
      moment,
      selectedSubject,
      subjects,
      loading,
      validateStat,

      currentPage,
      paginatedData,
      handleCurrentChange,
      isModalVisible,
      filteredDocuments,
      getLevelNames,
      remainingSubjects,
      remainingLevels,
      showModal,
      hideModal,
      handleRowClick,
    };
  },
});
